//借还记录已还清
<template>
  <div class="examRule">
    <el-dialog
      title=""
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      width="900px"
    >
      <div class="examRule_head flex">
        <span>{{ status == 0 ? '审核中' : status == 1 ? '审核成功' : '审核失败' }}详情</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <!--audit-detail start-->
      <div class="audit-detail">
        <ul class="personage_ul">
          <li>
            <div class="personage_ul_img m-r-20">
              <img
              v-if="details.fang"
                :src="details.fang.fang_fengmian_image"
                alt=""
              />
              <div class="personage_img_marking">{{ details.fang ? details.fang.fang_level : '' }}</div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="t-flex">
                <div class="t-tag m-r-15" v-if="details.fang">{{ getHouseType(details.fang.fang_type1) }}</div>
                <div class="personage_ul_name">{{ details.fang ? details.fang.name : '' }}</div>
              </div>

              <div class="personage_ul_title">
                {{ details.fang ? details.fang.fang_xing_copy : '' }} | {{ details.fang ? details.fang.fang_mianji : '' }}m² | {{ details.fang ? details.fang.yezhu_name : '' }}
              </div>
              <div class="personage_ul_tags" v-if="details.fang">
                <span class="tag" v-for="(item,index) in details.fang.fang_biaoqian" :key="index">{{ item }}</span>
              </div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <div class="personage_ul_price_left m-r-20">
                    <span>{{ details.fang ? details.fang.can_price : '' }}元/月</span>
                    <!-- <img src="../assets/image/personage/upb.png" alt="" /> -->
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <!--detail-item start-->
        <div class="detail-item">
          <div class="tit">客户信息</div>
          <div class="item">
            <div class="li"><span class="color-9">客户姓名：</span> {{ details.kehu_name }}</div>
            <div class="li">
              <span class="color-9">电话：</span> {{ details.kehu_mobile }}
            </div>
          </div>
        </div>
        <!--detail-item end-->
        <!--detail-item start-->
        <div class="detail-item" @click="openDocument">
          <div class="tit-flex">
            <div class="tit">合同信息</div>
            <div class="item-icon">
              <img src="../assets/image/personage/more.png" />
            </div>
          </div>
        </div>
        <!--detail-item end-->
        <!--detail-item start-->
        <div class="detail-item">
          <div class="tit">其他信息</div>
          <div class="item">
            <div class="li">
              <span class="color-9">发起时间：</span> {{ details.createtime }}
            </div>
          </div>
        </div>
        <!--detail-item end-->
        <div class="examRule_footer flex" v-if="type == 2 && status == 0">
          <el-button @click="changeItem(index)" v-for="(item,index) in 2" :key="index">{{ index == 0 ? '拒绝' : '同意' }}</el-button>
        </div>
      </div>
      <!--audit-detail end-->
      
    </el-dialog>
    <audit-pop-re ref="pop" @succend="succend"></audit-pop-re>
  </div>
</template>

<script>
import AuditPopRe from './AuditPopRe.vue'
export default {
  components:{AuditPopRe},
  data() {
    return {
      id:null,//
      status:0,//
      type:null,//
      dialogVisible: false,
      details: {}, //详情
    };
  },
  methods: {
    openDocument () {
      window.open("https://view.officeapps.live.com/op/view.aspx?src="
  +encodeURIComponent(this.details.contract_image))
      // location.href = 
    },
    succend () {
      this.dialogVisible = false
      this.$emit('succeed')
    },
    changeItem (index) {
      if (index == 0) {
        this.$refs.pop.open(this.id,5)

      } else {
        this.$axios.shenHeContractT({id:this.id}).then(res=>{
          this.succend()
          this.$message({
            message: res.msg,
            type: 'success'
          });
        })
      }
    },
    //关闭
    close() {
      this.dialogVisible = false;
    },
    //打开
    open(id,status,type) {
      this.id = id
      this.type = type
      this.status = status
      this.$axios.shenHeContractDetail({id}).then((res) => {
        this.dialogVisible = true;
        this.details = res.data;
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header,
/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog {
  border-radius: 8px;
  overflow: hidden;
}

.examRule_footer {
  padding: 0 70px;
  margin-top: 40px;
  /deep/ .el-button {
    width: 320px;
    height: 56px;
    color: #FF5151;
    font-size: 18px;
    background: #FDDFDF;
    border-radius: 8px;
    border: none;
    &:last-child {
      color: #FFFFFF;
      background: #3273F6;
    }
  }
}
.record_history {
  padding: 20px;
  .history_item {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px 20px 20px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    line-height: 1.8;
  }
  .history_item:nth-last-child(1) {
    border: none;
  }
}
.personage_ul {
  li {
    display: flex;
    .t-flex {
      display: flex;
      .t-tag {
        background: #e1ebff;
        border-radius: 3px;
        line-height: 28px;
        padding: 0px 10px;
        font-size: 14px;
        color: #3273f6;
      }
    }
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;
        line-height: 28px;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_tags {
        display: flex;
        margin: 0 -5px;
        flex-wrap: wrap;
        .tag {
          background: #f5f5f5;
          height: 28px;
          border-radius: 3px;
          padding: 0 20px;
          margin: 5px;
          font-size: 14px;
          color: #999;
          line-height: 28px;
        }
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
.audit-detail {
  padding: 30px;
  .detail-item {
    margin: 25px 0px;
    .tit-flex {
      display: flex;
      cursor: pointer;
      align-items: center;
    }
    .item-icon {
      margin-left: 10px;
    }
    .tit {
      font-size: 18px;
      margin: 10px 0px;
    }
    .item-img img {
      width: 120px;
      height: 120px;
    }
    .item {
      display: flex;
      margin: 5px 0px;
      line-height: 1.6;
      font-size: 16px;
      .li {
        width: 50%;
        line-height: 1.6;
        margin: 5px 0px;
      }
    }
  }
}
</style>

<template>
  <div class="comm-page">
    <!--rate_page_head start-->
    <div class="rate_page_head flex flex-between">
      <div class="rate_nav flex">
        <div @click="changeHeadIndex(index)" class="li" :class="{ active: index == from.shen_status }"
          v-for="(item, index) in headList" :key="index">
          {{ item }}
        </div>
      </div>
    </div>
    <!--rate_page_head end-->
    <!--honor-head start-->
    <div class="honor-head">
      <div class="head-nav">
        <div class="nav-link li" @click="changeType(1)" :class="{ active: 1 == from.type }">我的审核</div>
        <div v-if="userInfo.position != 4 && userInfo.position != 5" class="nav-link li" @click="changeType(2)"
          :class="{ active: 2 == from.type }">审核他人</div>
      </div>
    </div>
    <!--honor-head end-->
    <!--ind-main start-->
    <div class="ind-main" v-if="list.length">
      <!--audit-list start-->
      <div class="audit-list">
        <!--audit-item start-->
        <div class="audit-item" @click="$refs.popd.open(item.id, from.shen_status, from.type)" v-for="(item, index) in list"
          :key="index">
          <div class="item-l">
            <div class="item-img">
              <img src="../../assets/image/personage/a1.jpg" />
            </div>
            <div class="item-txt">
              <div class="t">房屋租赁合同</div>
              <div class="c color_orange">
                {{ item.type == 1 ? "在线签约" : "线下签约" }}
              </div>
            </div>
          </div>
          <div class="item-r">
            <div class="flex" v-if="from.shen_status == 0 && from.type == 2">
              <el-button class="audit-list-btn red m-r-10 m-b-10">拒绝</el-button>
              <el-button class="audit-list-btn blue m-b-10">同意</el-button>
            </div>
            <div class="item-date">{{ item.createtime }}</div>
          </div>
        </div>
        <!--audit-item end-->
      </div>
      <!--audit-list end-->
    </div>
    <!--ind-main end-->

    <div class="paging">
      <el-pagination background @current-change="handleCurrentChange"
        :current-page.sync="from.page" :page-size="10" layout="prev, pager, next, jumper" :total="from.total">
      </el-pagination>
    </div>

    <!--线上弹框 S -->
    <audit-pop-d ref="popd" @succeed="init"></audit-pop-d>
    <!-- 线上弹框 E -->
    <!--线下弹框 S -->
    <audit-pop-do ref="popdo"></audit-pop-do>
    <!-- 线下弹框 E -->
  </div>
</template>

<script>
import AuditPopD from "../../components/AuditPopD";
import AuditPopDo from "../../components/AuditPopDo";
import { mapGetters } from "vuex";
export default {
  components: { AuditPopD, AuditPopDo },
  computed: {
    // 使用对象展开运算符将 getter 混入 computed 对象中
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      page: 1, //页数
      total: 0, //条数
      list: [], //列表
      headIndex: 0, //头部切换
      headList: ["审核中", "审核成功", "审核失败"],
      from: {
        type: 1,
        shen_status: 0,
        page: 1,
        total:0
      },
    };
  },
  methods: {
    //切换类型
    changeType(index) {
      if (this.from.type == index) return
      this.from.type = index;
      this.init();
    },
    //切换头部
    changeHeadIndex(index) {
      this.from.shen_status = index;
      this.init();
    },
    init() {
      this.from.page = 1;
      this.list = [];
      this.getshenHeContract();
    },
    getshenHeContract() {
      this.$axios.shenHeContract(this.from).then((res) => {
        this.list = res.data.data;
        this.from.total = res.data.total;
      });
    },
    handleCurrentChange (e) {
      this.from.page = e;
      this.getshenHeContract();
    }
  },
  created() {
    this.getshenHeContract();
  }
};
</script>

<style scoped lang="less">
.rate_page_head {
  background: #fff;
  padding: 0 40px;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #ebebeb;

  .rate_nav {
    .li {
      font-size: 18px;
      color: #666;
      margin-right: 55px;
      cursor: pointer;
      position: relative;
      line-height: 64px;
    }

    .active {
      color: #3273f6;
    }

    .active:after {
      width: 24px;
      height: 2px;
      background: #3273f6;
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -12px;
      display: block;
      content: "";
    }
  }
}

.honor-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 40px;
  background: #fff;
  margin-bottom: 10px;
  align-items: center;

  .head-nav {
    display: flex;
  }

  .li {
    cursor: pointer;
    margin-right: 55px;
    line-height: 36px;
  }

  .nav-link {
    font-size: 16px;
    color: #666;
    line-height: 36px;
  }

  .active {
    color: #3273f6;
  }
}

.ind-add-btn-o {
  width: 88px;
  height: 36px;
  border-radius: 4px;
  background: #ffa740;
  padding: 0px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  border: none;
}

.ind-main {
  background: #fff;
  padding: 10px 40px;
}

.audit-list {
  .audit-list-btn {
    height: 40px;
    border-radius: 4px;
    padding: 0 24px;
    font-size: 16px;
    line-height: 40px;
    border: none;
    outline: none;
    position: relative;
    z-index: 11;
  }

  .audit-list-btn.blue {
    background: #3273f6;
    color: #fff;
  }

  .audit-list-btn.red {
    background: #fddfdf;
    color: #ff5151;
  }

  .audit-item {
    cursor: pointer;
    border-bottom: 1px solid #ebebeb;
    padding: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;

    .item-l {
      display: flex;
      width: calc(100% - 150px);

      .item-img img {
        width: 64px;
        height: 64px;
        border-radius: 4px;
      }

      .item-txt {
        margin-left: 16px;
        width: calc(100% - 80px);

        .t {
          font-size: 18px;
          margin: 5px 0px;
          line-height: 1.6;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .c {
          font-size: 14px;
          margin: 5px 0px;
          line-height: 1.6;
        }
      }
    }

    .item-date {
      font-size: 14px;
      color: #999;
      min-width: 120px;
      text-align: right;
    }
  }

  .audit-item:before {
    display: block;
    position: absolute;
    top: 50%;
    left: -20px;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #ff2121;
    content: "";
  }

  .audit-item:nth-last-child(1) {
    border: none;
  }
}</style>

//借还记录已还清
<template>
  <div class="examRule">
    <el-dialog
        title=""
        :show-close="false"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="900px"
    >
      <div class="examRule_head flex ">
        <span>审核中详情</span>
        <i class="el-icon-circle-close" @click="close"></i>
      </div>
      <!--audit-detail start-->
      <div class="audit-detail">
        <ul class="personage_ul">
          <li>
            <div class="personage_ul_img m-r-20">
              <img
                  src="https://pic1.ajkimg.com/display/ajk/f4ef3c1ab08cb7a9ed376343005a85ea/640x420c.jpg?t=1"
                  alt=""
              />
              <div class="personage_img_marking">A类</div>
            </div>
            <div class="personage_ul_main f-1">
              <div class="t-flex">
                <div class="t-tag m-r-15">商铺</div>
                <div class="personage_ul_name">富田城·九鼎华府富田城·九鼎华府</div>
              </div>

              <div class="personage_ul_title">
                2室1厅1厨1卫 | 88 | 8次 | 2天 | 王珊珊
              </div>
              <div class="personage_ul_tags">
                <span class="tag">独家房源</span>
                <span class="tag">有车位</span>
                <span class="tag">有视频</span>
              </div>
              <div class="personage_ul_price flex-between">
                <div class="flex">
                  <div class="personage_ul_price_left m-r-20">
                    <span>1800元/月</span>
                    <img src="../assets/image/personage/upb.png" alt="" />
                  </div>
                </div>
              </div>

            </div>
          </li>
        </ul>
        <!--detail-item start-->
        <div class="detail-item">
          <div class="tit">客户信息</div>
          <div class="item">
            <div class="li"><span class="color-9">客户姓名：</span> 石小沫</div>
            <div class="li"><span class="color-9">电话：</span> 13584589521</div>
          </div>
        </div>
        <!--detail-item end-->
        <!--detail-item start-->
        <div class="detail-item">
          <div class="tit">合同信息</div>
          <div class="item">
            <div class="item-img">
              <img src="../assets/image/personage/other.png">
            </div>
          </div>
        </div>
        <!--detail-item end-->
        <!--detail-item start-->
        <div class="detail-item">
          <div class="tit">其他信息</div>
          <div class="item">
            <div class="li"><span class="color-9">电话：</span> 13584589521</div>
          </div>
        </div>
        <!--detail-item end-->
      </div>
      <!--audit-detail end-->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible:false,
      content:'',//借还记录
    }
  },
  methods:{
    //关闭
    close () {
      this.dialogVisible = false
    },
    //打开
    open () {
      this.$axios.kaoShi().then(res=>{
        this.dialogVisible = true
        this.content = res.data.content
      })
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header ,/deep/ .el-dialog__body {
  padding: 0 !important;
}
/deep/ .el-dialog{border-radius:8px;overflow:hidden}

// .examRule_main {
//   // line-height: 28px;
// }
.record_history{
  padding:20px;
  .history_item{border-bottom:1px solid #EBEBEB;padding:10px 20px 20px 20px;font-size:16px;display:flex;justify-content:space-between;line-height:1.8}
  .history_item:nth-last-child(1){border:none;}
}
.personage_ul {
  li {
    display: flex;
    .t-flex{
      display:flex;
      .t-tag{background:#E1EBFF;border-radius:3px;line-height:28px;padding:0px 10px;font-size:14px;color:#3273F6;}
    }
    .personage_ul_img {
      img {
        width: 220px;
        height: 165px;
        border-radius: 8px;
      }
      position: relative;
      .personage_img_marking {
        top: 0;
        left: 0;
        position: absolute;
        width: 56px;
        height: 32px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        line-height: 32px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px 0px 8px 0px;
      }
    }
    .personage_ul_main {
      height: 165px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .personage_ul_name {
        color: #333333;
        font-size: 18px;
        font-weight: bold;line-height:28px;
      }
      .personage_ul_title {
        color: #999999;
        font-size: 14px;
      }
      .personage_ul_price {
        display: flex;
        align-items: flex-end;
        .personage_ul_price_left {
          display: flex;
          align-items: center;
          span {
            color: #ff2121;
            font-size: 22px;
            font-weight: bold;
          }
        }
        .personage_ul_price_right {
          display: flex;
          align-items: center;
          span {
            color: #999999;
            font-size: 18px;
          }
        }
        img {
          width: 11px;
          height: 14px;
          margin-left: 10px;
        }
      }
      .personage_ul_footer {
        width: 100%;
        align-items: flex-end;
        .personage_ul_footer_phone {
          width: 36px;
          height: 36px;
          background: #ffe7e3;
          border-radius: 50%;
        }
        .personage_ul_footer_img {
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
          margin-right: 10px;
        }
        .personage_ul_footer_main {
          height: 48px;
          align-items: flex-start;
          flex-direction: column;
        }
        .personage_ul_footer_name {
          color: #333333;
          font-size: 16px;
          font-weight: bold;
        }
        .personage_ul_footer_time {
          span {
            color: #ff9600;
            font-size: 14px;
            &:first-child {
              margin-right: 40px;
            }
          }
        }
      }
    }
  }
}
.audit-detail{
  padding:30px;
  .detail-item{
    margin:25px 0px;
    .tit{font-size:18px;margin:10px 0px;}
    .item-img img{width:120px;height:120px;}
    .item{
      display:flex;margin:5px 0px;line-height:1.6;font-size:16px;
      .li{width:50%;line-height:1.6;margin:5px 0px;}
    }
  }
}
</style>
